<template>
  <section>
    <div
      class="pop-right-dialog-content"
      :class="{'close': isClose, 'show': isShow}"
      v-click-outside="closeModal"
    >
      <div class="popHead">
        <ul class="tabList">
          <li class="tabItem" :class="item.id === avtiveId? 'tabItem activeItem':'tabItem'" v-for="item in messageTabList" :key="item.id" @click="hanledItem(item.id)">
            {{ item.text }}
            <span class="active_bar" v-if="item.id === avtiveId"></span>
          </li>
        </ul>
      <div class="readAll" @click="setEnterpriseNoticeRead(null)" v-if='unReadCount>0'>全部已读</div>
      </div>
      <div class="tab_readCon" v-if="totalCount>0">
        <div :class="readState==1?'read_active':''" @click="tabChange(1)">未读({{ unReadCount }})</div>
        <div :class="readState==0?'read_active':''" @click="tabChange(0)">全部({{totalCount}})</div>
      </div>
      <ul @scroll="onScroll($event)" class="meassage_list" v-if='(unReadCount>0&&readState==1)||(totalCount>0&&readState==0)'>
        <li v-for="item in messageList" :key="item.noticeId">
          <div class="messageCenterPop-list" :data-message-id="item.noticeId" @click="messageClicked(item)">
            <div
              class="list-left"
              :style="
                item.noticeKind === 1
                  ? 'background: linear-gradient(180deg, #0773fc 0%, #074cfc 100%);'
                  : 'background: linear-gradient(180deg, #fcd307 0%, #fcaa07 100%);'
              "
            >
              <img
                src="./img/system-message-read.svg"
                v-if="item.noticeKind === 1"
              />
              <img
                src="./img/team-message-read.svg"
                v-if="item.noticeKind === 2"
              />
              <span class="no-read" v-if="item.hasRead === 0"></span>
            </div>
            <div class="list-right">
              <img
                class="img-text-url"
                :src="item.imgTextUrl"
                v-if="item.noticeType === 5 && item.imgTextUrl"
              />
              <span class="list-head-title">
                {{ item.subject || item.title }}
              </span>
              <div
                class="list-main"
                :class="{ 'four-line-clamp': item.noticeType === 5 }"
                @click="messageClicked(item, $event)"
                v-html="item.content"
              ></div>
              <div class="list-date-warp">
                <span class="list-date">{{ getNoticeSendTime(item) }}</span>
                <span
                  class="btn-experience"
                  v-if="item.noticeType === 5 && item.imgTextButton"
                >
                  {{ item.imgTextButton }}
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="messageList.length==0" class="nomessageCon">
        <img :src="require('@/base/empty-glass.png')" height="160"/>
        <p>暂无消息通知</p>
      </div>
      <div class="mes_close" @click="closeModal">
        <img src="../img/message_close.svg"/>
      </div>
    </div>
    <div class="pop-right-dialog-content-mask" v-if="shouldShowPopMask" :style="activityFlag ? 'top:103px' : 'top:68px'">
      <div class="pop-img" v-if="latestPopMessage.imgTextUrl">
        <img
          :src="latestPopMessage.imgTextUrl"
          :alt="latestPopMessage.subject"
          @error="handleImageError"
        />
      </div>
      <div class="pop-content-warp"  @click="messageClicked(latestPopMessage, '', 'button')">
        <span class="pop-title">{{ latestPopMessage.subject }}</span>
        <div class="pop-content" v-html="latestPopMessage.content"></div>
        <span class="pop-dateTime">{{
          getNoticeSendTime(latestPopMessage)
        }}</span>
        <button
          class="pop-btn"
          v-if="latestPopMessage.imgTextButton"
        >
          {{ latestPopMessage.imgTextButton }}
        </button>
      </div>
      <div class="pop-close-btn" @click="messageClicked(latestPopMessage, '', 'close')">
        <img src="./img/pop-close.svg" />
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import tracker from "@/utils/tracker";
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MessageCenterPop',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean
    },
    activityFlag: {
      type: Boolean,
      default: false,
    },
    isProVip: {
      type: Boolean,
      default: false
    },
    messageCountInfo: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
  },
  data() {
    return {
      messageList: [],
      messageTabList:[{id:0,text:'全部消息'},{id:2,text:'团队消息'},{id:1,text:'系统消息'}],
      showList: {
        title: '',
        date: '',
        main: ''
      },
      pageNum: 1,
      avtiveId:0,
      isClose: false,
      showTime: 0,
      totalCount:0,
      unReadCount:0,
      readState:1,// 0 全部 1未读 2 已读
      popList: [],
      popToast: false,
      observer: null,
      visibleMessages: new Set(), // 存储当前可见的消息ID

    }
  },
  computed:{
    ...mapGetters({
      teamId: 'getTeamId',
    }),
    // 获取最新的弹窗消息
    latestPopMessage() {
      return this.popList.length > 0
        ? this.popList[this.popList.length - 1]
        : null;
    },
    shouldShowPopMask() {
      return this.popToast && this.latestPopMessage && this.isLogin;
    },
  },
  watch: {
    pageNum (v) {
      if(v===1||this.messageList.length>=this.totalCount) return
        this.getNoticeMethod()
    },
    isShow (v) {
      if (v) {
        this.showTime = Date.now()
        this.observeListItems();
      }
    },
    isLogin (v) {
      if (v) {
        this.getNoticeMethod()
      }
    },
    isProVip() {
      this.getNoticeMethod()
    },
    teamId(v){
      if(v){
        this.avtiveId = 0
      }
    },
    messageCountInfo:{
      handler(v){
        this.totalCount = v.kindTotalCount
        this.unReadCount = v.kindUnReadCount
      },
      immediate:true
    },
    shouldShowPopMask(newVal) {
      if (newVal && this.latestPopMessage) {
        this.reportVisibleMessage(
          this.latestPopMessage,
          "message_show",
          "",
          "button",
        );
      }
    },
  },
  beforeMount () {
    this.getNoticeMethod()
  },
  mounted() {
    // 初始化 Intersection Observer
    this.initIntersectionObserver();
  },
  beforeDestroy() {
    // 清理 observer
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    onScroll(event) {
      var offsetHeight = event.currentTarget.offsetHeight,
          scrollHeight = event.target.scrollHeight,
          scrollTop = event.target.scrollTop,
          scrollBottom = offsetHeight + scrollTop
      if (scrollBottom === scrollHeight || scrollBottom === scrollHeight + 2) {
        this.pageNum++
      }
    },
    getNoticeMethod() {
      if (!this.isLogin) return
      this.getUserNotice()
    },
    hanledItem(itemId){
      this.avtiveId =itemId
      this.readState = 1
      this.getUserNotice()
    },
    tabChange(readState){
      this.readState = readState
      this.getUserNotice()
    },
    // 获取主站用户消息列表
    getUserNotice() {
      this.$http.get('/mbox/notice/getNoticeList', {params: {readState: this.readState, pageNo: this.pageNum,noticeKind: this.avtiveId }, data: { gw: true, template: true, nottrans: true },}).then(res => {
        // if (res.body.code) return  // 无通知记录(code与noticeList不共存)
        if (res.body.code == -401) {
          this.$message('请登录')
          this.$emit('set-logreg-show', 1)
          return
        }
        const nList = res.body.data&&res.body.data.list
        let arr = []
        this.popList = []; // 重置 popList
        for (let i in nList) {
          if (nList[i].noticeType === 0 || nList[i].noticeType === 5) {
                arr.push(nList[i]);
                // 如果是 noticeType === 5 的消息，添加到 popList
                if (nList[i].noticeType === 5) {
                  let message = nList[i];
                  message.content = this.formatContent(message.content);
                  if (message.hasRead === 0) {
                    this.popList.push(nList[i]);
                    this.popToast = true;
                  }
                }
              }
          if (nList[i].noticeType === 3  || nList[i].noticeType === 4) {
            let message = nList[i]
            if (message.templateNotice) {
              let templateNotice = message.templateNotice
              if (templateNotice.mainTitle) {
                message.subject = this.getPlainText(templateNotice.mainTitle)
              }
              message.content = `${this.getPlainText(templateNotice.subTitle)}` || ''
              if (templateNotice.content) {
                message.content += `${this.getPlainText(templateNotice.content)}`
              }
              if (templateNotice.button) {
                message.content += `<a href="${templateNotice.button.url ? templateNotice.button.url : `/messagecenter?id=${message.noticeId}`}" target="_blank">${ templateNotice.button.text ? templateNotice.button.text : '点击查看'}</a>`
              }
            }
            arr.push(message)
          }
        }
        this.messageList = arr
        this.$emit('getNoticeCount',this.avtiveId)
        this.$nextTick(() => {
          this.observeListItems();
        });
      }, res => {
        console.log('error')
      })
    },
    // 获取专业版用户消息列表
    async getEnterpriseNotice() {
      const params = {
        page_no: this.pageNum,
        page_size: 10,
        gw: true
      }
      try {
        const res = await this.$http.post('/team/enterprise/notice/getEnterpriseNotice', params) || {}
        if (res.body.code === 200) {
          const { recordData } = res.body.data
          recordData.map((item) => {
            item.noticeId = item.id
            item.hasRead = item.readState
          })
          if (this.pageNum > 1) {
            this.messageList = [...this.messageList, ...recordData]
          } else {
            this.messageList = recordData
          }
        } else {
          this.messageList = []
          this.$message('获取消息列表失败')
        }
      } catch (error) {
        this.messageList = []
        this.$message('获取消息列表失败')
      }
    },
    // 获取消息的发送时间
    getNoticeSendTime(notice) {
      const time = notice.sendTime
      return dayjs(time).format('YYYY-MM-DD')
    },
    // 点击消息
    messageClicked(item = "", event, type) {
      if (event) {
        event.stopPropagation();
      }
      this.setEnterpriseNoticeRead(item.noticeId);
      this.reportVisibleMessage(item, "message_click", event, type);
      if (item.noticeType === 5) {
        if (
          this.popToast &&
          this.latestPopMessage &&
          this.latestPopMessage.noticeId === item.noticeId
        ) {
          this.popToast = false;
        }
        if (type === "close" || !item.noticeUrl) return;
        window.open(item.noticeUrl);
        return;
      }
      if (event && event.target.tagName === 'A') return
      window.open(`/messagecenter?id=${item.noticeId}`)
    },
    getPlainText(str) {
      if(str){
        return str.replace(/<[^>]*>|/g, "")
      }else{
        return str
      }
    },
    formatContent(content) {
      if (!content) return "";
      return content.replace(/\n/g, "<br>");
    },
    closeModal () {
      // 点击空白处关闭：防止在外部打开后立即关闭
      if (Date.now() - this.showTime < 100 || !this.isShow) return

      this.isClose = true
      this.$emit('close')
      //  动画执行完之后清除isClose的值
      setTimeout(() => {
        this.isClose = false
      }, 600);
    },
    // 企业消息读取状态更改
    async setEnterpriseNoticeRead(noticeId) {
      // if (!this.isProVip) return;
      const params = { 
        noticeId: noticeId,
        gw: true
      }
      const res = await this.$http.post('/mbox/notice/setNoticeAsHasRead', params) || {}
      if (res.body.code !== 200) {
        console.log('setEnterpriseNoticeRead',res.body.code)
        return
      }
      // 重新获取消息,防止企业消息红点不刷新
      this.getUserNotice()
      this.$emit('setReadSuccess')
    },
    // 处理图片加载错误
    handleImageError(e) {
      e.target.style.display = 'none';
    },
    initIntersectionObserver() {
      this.observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            const messageId = entry.target.dataset.messageId;

            if (entry.isIntersecting) {
              this.visibleMessages.add(messageId);
              const messageData = this.messageList.find(
                item => item.noticeId == messageId,
              );
              if (messageData) {
                this.reportVisibleMessage(messageData, "message_show");
              }
            } else {
              this.visibleMessages.delete(messageId);
            }
          });
        },
        {
          threshold: 0.5, // 元素可见面积超过50%时触发
        },
      );
    },
    reportVisibleMessage(messageData, event_name, event, type) {
      // 上报逻辑
      const messageTypeMap = {
        0: "全部消息",
        1: "系统消息",
        2: "团队消息",
      };
      const messageType = messageTypeMap[this.avtiveId];
      const readStatus = this.readState === 1 ? "未读" : "全部";
      const forward_module_name = type
        ? "消息弹框"
        : `${messageType}_${readStatus}`;
      const params = {
        forward_module_name,
        forward_page_name: "消息盒子",
        message_id: messageData.noticeId,
        message_type: messageData.noticeType,
        message_name: messageData.subject,
        message_content: messageData.content,
        message_target: messageData.noticeUrl,
        ...tracker.baseParams,
      };
      if (event_name === "message_click") {
        params.click_type = event
          ? "点击内链"
          : type === "close"
          ? "关闭消息"
          : "点击消息";
      }
      window.gio("track", event_name, params);
    },
    observeListItems() {
      // 先取消之前的观察
      if (this.observer) {
        this.observer.disconnect();
      }

      // 观察所有消息列表项
      setTimeout(() => {
        const messageItems = this.$el.querySelectorAll(
          ".messageCenterPop-list",
        );
        messageItems.forEach(item => {
          this.observer.observe(item);
        });
      }, 100);
    }

    // //获取用户消息数量
    // async getUserNoticeCount(){
    //   const params = { 
    //     gw: true,
    //     noticeKind: this.avtiveId
    //   }
    //   const res = await this.$http.post('/mbox/notice/getUserNoticeCount', params) || {}
    //   if (res.body.code == 200) {
    //     this.totalCount = res.body.data.kindTotalCount
    //     this.unReadCount = res.body.data.kindUnReadCount
    //   }
    // }
  }
}
</script>

<style lang="less">
.messageCenterPop-list .list-main {
  * {
    box-sizing: border-box;
    font-size: 12px !important;
  }
  a {
    color: #0773fc!important;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

<style lang="less" scoped>
  @import "messageCenterPop.less";
</style>
