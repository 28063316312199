<template>
 <LtModal
      v-model="bindfailModalShow"
      title="绑定失败"
      :footer-hide="true"
      width="540"
      :simple="false"
      @on-cancel="closeModal"
    >   
    <p class="content">
      您输入的手机号“{{phone}}”已在创客贴平台注册账号或被绑定至其他账号。您可以登录此手机号账号，进入个人中心先解除绑定，再绑定至当前账号中。<span @click="goCoursePage">查看操作教程</span>
    </p>
    <p class="warn">温馨提示：解除绑定后，您将无法通过手机号登录原账号。</p>
    <div class="btn" @click="showBindPhone">我知道了</div>
    <p class="tip" @click="contactService">遇到问题？联系客服</p>
 </LtModal>
</template>
<script>
import CryptoJS from 'crypto-js'
export default {
  data() {
    return {
      bindfailModalShow: false
    }  
  },
  props: {
    userInfo: {
      type: Object,
    },
    bindFailShow: {
      type: Boolean,
      default: false
    },
    phone: {
      type: String,
      default: ''
    }
  },
   watch: {
    bindfailModalShow(v){
      if (!v){
        this.$emit("closeFailToast")
      }
    },
    bindFailShow(v) {
      if (v){
        this.bindfailModalShow = v
      }
    }
  },
  methods:{
    goCoursePage(){
      window.open('https://support.qq.com/products/55374/faqs/125904')
    },
    showBindPhone(){
      this.bindfailModalShow = false
      this.$emit("showBindPhone")
    },
    closeModal(){
      this.bindfailModalShow = false
    },
    contactService() {
      try {
        const userId = this && this.userInfo && this.userInfo.userId || '未登录'
        const nickname =this && this.userInfo && this.userInfo.nickname || '未登录'
        const vipLevelName = this && this.userInfo && this.userInfo.vipLevelName || '普通用户'
        const time = new Date().getTime()
        const signature = CryptoJS.SHA1(`nonce=${time}&timestamp=${time}&web_token=${userId}&1e15f01a61353b917f3f40df371d44d1`).toString().toUpperCase();
        ud('init')
        ud({
          'customer': {
            "nonce": time,
            "signature": signature,
            "timestamp": time,
            "web_token": userId,
            "im_user_key": '1e15f01a61353b917f3f40df371d44d1',
            "c_cf_用户ID": userId,
            "c_cf_用户昵称": nickname,
            "c_cf_用户会员": vipLevelName,
            "c_cf_来源入口": '绑定手机号失败_联系客服'
          }
        })
        ud('showPanel')
      } catch (e) {
        console.log(e, 'init udesk api is error')
      }
    },
  }
}
</script>

<style scoped lang="less">
.content{
  color: #505A71;
  font-size: 14px;
  line-height: 20px;
  span{
    color: #0773FC;
    cursor: pointer;
  }
}
.warn{
  margin-top: 16px;
  color: #FA2323;
  font-size: 12px;
}
.btn{
  cursor: pointer;
  margin: 0 auto;
  margin-top: 32px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: #0773FC;
  width: 240px;
  height: 40px;
  line-height: 40px;
}
.tip{
  cursor: pointer;
  margin-top: 16px;
  text-align: center;
  color: #505A71;
  font-size: 14px;
}
</style>