const NAV_LIST = [
    {
        href: "/designtools/",
        des: "首页",
        key: "degisntools",
    },
    {
        des: '模板素材',
        key: 'template_seo',
    },
    {
        des: "AI工具箱",
        key: "aitools_seo"
    },
    {
        des: "定制设计",
        key: "designservice_seo"
    },
    {
        href: "/print/printcenter",
        des: "印刷制作",
        key: "printcenter"
    },
    {
        href: "/clientpage",
        des: "下载APP",
        key: "appdownload"
    },
    {
        des: "更多",
        width: 28,
        key: "more"
    }
]
const MORE_LIST = [
    {
        des: "企业旗舰版",
        href: "/contech",
    },
    {
        des: "屏幕营销",
        href: "/screen-marketing",
    },
    {
        des: "视频协作",
        href: "/video-cooperation",
    },
    {
        des: "公益版",
        href: "/enterpriseSaasLandPage",
    },
    {
        des: "渠道合作",
        href: "/activity/channelAgentPage",
    },
]
const TEMPLATE_LIST = [
    {
        des: "市场营销",
        list: [
            {
                des: "海报",
                href: "https://www.chuangkit.com/lpad/haibaozxsjzzlp.html"
            },
            {
                des: "长图",
                href: "https://www.chuangkit.com/lpad/ctzxsjzzlp.html"
            },
            {
                des: "H5",
                href: "https://www.chuangkit.com/lpad/h5ymzxsjzzlp.html"
            },
            {
                des: "DM宣传单",
                href: "https://www.chuangkit.com/lpad/xcdzxsjzzlp.html"
            },
            {
                des: "印刷海报",
                href: "https://www.chuangkit.com/lpad/yshbzxsjzzlp.html"
            },
            {
                des: "易拉宝",
                href: "https://www.chuangkit.com/lpad/ylbzxsjlp.html"
            },
            {
                des: "三折页",
                href: "https://www.chuangkit.com/lpad/mfzxzzszylp.html"
            },
            {
                des: "宣传册",
                href: "https://www.chuangkit.com/lpad/xuanchuancelp.html"
            },
            {
                des: "展架",
                href: "https://www.chuangkit.com/lpad/zhanjiashejilp.html"
            },
            {
                des: "LOGO",
                href: "https://www.chuangkit.com/lpad/logomfsjzxsc.html"
            },
            {
                des: "展板",
                href: "https://www.chuangkit.com/lpad/zbzxsjzzlp.html"
            },
            {
                des: "价目表/菜单",
                href: "https://www.chuangkit.com/lpad/cdjmbzxsjzzlp.html"
            },
        ]
    },
    {
        des: "社交媒体",
        list: [
            {
                des: "小红书",
                href: "https://www.chuangkit.com/lpad/xhstwmblp.html"
            },
            {
                des: "公众号首图",
                href: "https://www.chuangkit.com/lpad/gzhfmtzz.html"
            },
            {
                des: "公众号小图",
                href: "https://www.chuangkit.com/lpad/gzhxtmblp.html"
            },
            {
                des: "每日一签",
                href: "https://www.chuangkit.com/lpad/mfzxsjzzrqlp.html"
            },
            {
                des: "书单文案",
                href: "https://www.chuangkit.com/lpad/sdtpzxzzlp.html"
            },
            {
                des: "网文封面",
                href: "https://www.chuangkit.com/lpad/shujifengmian.html"
            },
            {
                des: "视频封面",
                href: "https://www.chuangkit.com/lpad/shipinfengmian.html"
            },
            {
                des: "二维码",
                href: "https://www.chuangkit.com/lpad/erweimasjmblp.html"
            },
            {
                des: "抖音个人背景",
                href: "https://www.chuangkit.com/lpad/dygrbjtsjlp.html"
            },
        ]
    },
    {
        des: "商务办公",
        list: [
            {
                des: "PPT模板",
                href: "https://www.chuangkit.com/lpad/pptshejilp.html"
            },
            {
                des: "单页PPT",
                href: "https://www.chuangkit.com/lpad/dypptmblp.html"
            },
            {
                des: "名片",
                href: "https://www.chuangkit.com/lpad/mpzxsjlp.html"
            },
            {
                des: "工作证",
                href: "https://www.chuangkit.com/lpad/gzzzxzzsqlp.html"
            },
            {
                des: "日报简讯",
                href: "https://www.chuangkit.com/lpad/rbjxsjmblp.html"
            },
            {
                des: "简历模板",
                href: "https://www.chuangkit.com/lpad/jianlimuban.html"
            },
            {
                des: "竖版PPT",
                href: "https://www.chuangkit.com/lpad/shubanpptmb.html"
            },
            {
                des: "聘书奖状",
                href: "https://www.chuangkit.com/lpad/psjzmblp.html"
            },
            {
                des: "授权书",
                href: "https://www.chuangkit.com/lpad/sqsmblp.html"
            },
        ]
    },
    {
        des: "电商设计",
        list: [
            {
                des: "商品主图",
                href: "https://www.chuangkit.com/lpad/dianshangzhutu.html"
            },
            {
                des: "详情页",
                href: "https://www.chuangkit.com/lpad/dsxqylp.html"
            },
            {
                des: "图文带货",
                href: "https://www.chuangkit.com/lpad/twdhztgjlp.html"
            },
            {
                des: "优惠券",
                href: "https://www.chuangkit.com/lpad/yhqzxsjzzlp.html"
            },
            {
                des: "售后卡",
                href: "https://www.chuangkit.com/lpad/shouhouka.html"
            },
            {
                des: "直播间背景图",
                href: "https://www.chuangkit.com/lpad/zhibojianbeijingtu.html"
            },
            {
                des: "抖音团购图",
                href: "https://www.chuangkit.com/lpad/dytgtlp.html"
            },
            {
                des: "直播间贴片",
                href: "https://www.chuangkit.com/lpad/zbjtplp.html"
            },
            {
                des: "出海电商主图",
                href: "https://www.chuangkit.com/lpad/chuhaidszt.html"
            },
            {
                des: "视频商品主图",
                href: "https://www.chuangkit.com/lpad/spspzt.html"
            },
            {
                des: "美团海报",
                href: "https://www.chuangkit.com/lpad/meituanhblp.html"
            },
            {
                des: "超市DM单",
                href: "https://www.chuangkit.com/lpad/chaoshidmdan.html"
            }
        ]
    },
    {
        des: "教育培训",
        list: [
            {
                des: "课程封面",
                href: "https://www.chuangkit.com/lpad/kcfmlp.html"
            },
            {
                des: "讲师海报",
                href: "https://www.chuangkit.com/lpad/jiangshihblp.html"
            },
            {
                des: "招生海报",
                href: "https://www.chuangkit.com/lpad/zhaoshenghblp.html"
            },
            {
                des: "课程表",
                href: "https://www.chuangkit.com/lpad/kcbmblp.html"
            },
            {
                des: "放假通知",
                href: "https://www.chuangkit.com/lpad/fjtzmblp.html"
            },
            {
                des: "课件PPT",
                href: "https://www.chuangkit.com/lpad/kjpptmblp.html"
            },
            {
                des: "培训通知",
                href: "https://www.chuangkit.com/lpad/pxtzlp.html"
            },
            {
                des: "直播讲座海报",
                href: "https://www.chuangkit.com/lpad/zbjzhblp.html"
            },
            {
                des: "打卡分享海报",
                href: "https://www.chuangkit.com/lpad/qiandaohaibao.html"
            },
            {
                des: "签到海报",
                href: "https://www.chuangkit.com/lpad/spspzt.html"
            },
            {
                des: "高考海报",
                href: "https://www.chuangkit.com/lpad/gaokaohb.html"
            },
            {
                des: "读书海报",
                href: "https://www.chuangkit.com/lpad/dushuhaibao.html"
            },
            {
                des: "开学季",
                href: "https://www.chuangkit.com/lpad/kaixuejihb.html"
            }
        ]
    },
    {
        des: "素材字体",
        list: [
            {
                des: "插画素材",
                href: "https://www.chuangkit.com/material"
            },
            {
                des: "字体",
                href: "https://www.chuangkit.com/tcrf-polyme"
            },
            {
                des: "plog人像",
                href: "https://www.chuangkit.com/image/relevant?picType=3438"
            },
            {
                des: "电商套系服饰",
                href: "https://www.chuangkit.com/image/relevant?picType=3546"
            },
            {
                des: "城市风景",
                href: "https://www.chuangkit.com/image/relevant?picType=3520"
            },
            {
                des: "餐饮美食",
                href: "https://www.chuangkit.com/image/relevant?picType=3420"
            },
            {
                des: "自然风光",
                href: "https://www.chuangkit.com/image/relevant?picType=3521"
            },
            {
                des: "动物宠物",
                href: "https://www.chuangkit.com/image/relevant?picType=3523"
            },
            {
                des: "运动健康",
                href: "https://www.chuangkit.com/image/relevant?picType=3417"
            },
        ]
    }
]
const AI_TOOLS_LIST = [
    {
        des: "AI修图",
        list: [
            {
                des: "批量抠图",
                href: "https://www.chuangkit.com/lpad/koutulp.html"
            },
            {
                des: "智能消除",
                href: "https://www.chuangkit.com/lpad/eliminate.html"
            },
            {
                des: "图片变清晰",
                href: "https://www.chuangkit.com/lpad/tpbqx.html"
            },
            {
                des: "智能改图",
                href: "https://www.chuangkit.com/lpad/inpainting.html"
            },
            {
                des: "AI去水印",
                href: "https://www.chuangkit.com/lpad/aiqushuiyin.html"
            },
            {
                des: "无损改尺寸",
                href: "https://www.chuangkit.com/lpad/tpgcclp.html"
            },
            {
                des: "智能外拓",
                href: "https://aiart.chuangkit.com/print/outpainting"
            },
            {
                des: "AI文案",
                href: "https://aiart.chuangkit.com/print/writingassistant"
            }
        ]
    },
    {
        des: "智能设计",
        list: [
            {
                des: "一句话做设计",
                href: "https://www.chuangkit.com/lpad/aiznhbsj.html"
            },
            {
                des: "人物海报",
                href: "https://www.chuangkit.com/lpad/rewuhaibaoai.html"
            },
            {
                des: "每日一签",
                href: "https://www.chuangkit.com/lpad/riqianai.html"
            },
            {
                des: "商品主图",
                href: "https://www.chuangkit.com/lpad/productai.html"
            },
            {
                des: "大字封面",
                href: "https://www.chuangkit.com/lpad/dazifengm.html"
            },
            {
                des: "小红书配图",
                href: "https://www.chuangkit.com/lpad/readbookai.html"
            },
            {
                des: "小红书人物封面",
                href: "https://www.chuangkit.com/lpad/xhsrwfmai.html"
            },
            {
                des: "四宫格拼图",
                href: "https://www.chuangkit.com/lpad/sggptai.html"
            }
        ]
    },
    {
        des: "AI绘画",
        list: [
            {
                des: "文生图",
                href: "https://aiart.chuangkit.com/print/default"
            },
            {
                des: "文生素材",
                href: "https://aiart.chuangkit.com/print/default"
            },
            {
                des: "图生图",
                href: "https://aiart.chuangkit.com/print/default"
            },
            {
                des: "照片改漫画",
                href: "https://aiart.chuangkit.com/print/image2Cartoon"
            },
            {
                des: "文生绘本",
                href: "https://aiart.chuangkit.com/print/default"
            },
            {
                des: "文生人物",
                href: "https://aiart.chuangkit.com/print/default"
            },
            {
                des: "线稿上色",
                href: "https://aiart.chuangkit.com/print/scribble2color"
            },
            {
                des: "人物姿势识别",
                href: "https://aiart.chuangkit.com/print/pose2character"
            }
        ]
    },
    {
        des: "热门推荐",
        list: [
            {
                des: "AI商品图",
                href: "https://www.chuangkit.com/lpad/aiproductimage.html"
            },
            {
                des: "AILOGO",
                href: "https://www.chuangkit.com/lpad/ailogo.html"
            },
            {
                des: "AI拼图",
                href: "https://www.chuangkit.com/ai-design/jigsaw"
            },
            {
                des: "图片翻译",
                href: "https://aiart.chuangkit.com/editor/translation"
            },
            {
                des: "批量设计",
                href: "https://www.chuangkit.com/templatebatch"
            },
        ]
    }
]
const DESIGN_SERVICE_LIST = [
    {
        des: "品牌设计",
        list: [
            {
                des: "LOGO设计",
                href: "https://www.chuangkit.com/lpad/servicelogosheji.html"
            },
            {
                des: "IP形象设计",
                href: "https://www.chuangkit.com/lpad/ipxingxiang.html"
            },
            {
                des: "企业画册/宣传册",
                href: "https://www.chuangkit.com/lpad/huacesheji.html"
            },
            {
                des: "UI/网页设计",
                href: "https://www.chuangkit.com/lpad/uidesignlp.html"
            },
        ]
    },
    {
        des: "市场营销",
        list: [
            {
                des: "海报",
                href: "https://www.chuangkit.com/lpad/servicehaibaosheji.html"
            },
            {
                des: "展板/展架",
                href: "https://www.chuangkit.com/lpad/servicezbzjsheji.html"
            },
            {
                des: "KV",
                href: "https://www.chuangkit.com/lpad/servicekvsheji.html"
            },
            {
                des: "条漫/漫画",
                href: "https://www.chuangkit.com/lpad/servicetmmhsheji.html"
            },
        ]
    },
    {
        des: "电商设计",
        list: [
            {
                des: "banner",
                href: "https://www.chuangkit.com/lpad/bannershejivip.html"
            },
            {
                des: "详情页",
                href: "https://www.chuangkit.com/lpad/xiangqingyevip.html"
            },
        ]
    },
    {
        des: "商务办公",
        list: [
            {
                des: "PPT定制",
                href: "https://www.chuangkit.com/lpad/pptdingzhivip.html"
            },
        ]
    }
]
export default {
    NAV_LIST,
    MORE_LIST,
    TEMPLATE_LIST,
    AI_TOOLS_LIST,
    DESIGN_SERVICE_LIST
}