<template>
  <div class="home-page-container">
    <div class="home-page-scroll-box" style="position: relative">
      <!-- 右侧二维码 -->
      <div
        class="right-tool"
        @mouseleave="showQrCodeBox = false"
        v-show="scrollTop > getScreenHeight"
      >
        <div
          class="right-tool-block phone"
          @mouseenter="showQrCode('mobile')"
        ></div>
        <div class="right-tool-divide"></div>
        <div
          class="right-tool-block custom"
          @mouseenter="showQrCode('custom')"
        ></div>
        <div class="qrcode-box" v-show="showQrCodeBox">
          <div class="qrcode">
            <div
              class="qrcode-img"
              :style="{
                backgroundImage: `url(${require(`./img/qrcode-${qrcodeType}.png`)})`,
              }"
            ></div>
            <span v-show="qrcodeType === 'mobile'" class="qrcode-title"
              >iOS/Android</span
            >
            <span class="qrcode-des">{{
              qrcodeType === 'mobile'
                ? '下载APP畅享多端协作'
                : '咨询顾问了解企业定制化服务'
            }}</span>
          </div>
        </div>
      </div>
      <!-- 首屏 -->
      <div class="first-page" :style="{ height: getScreenHeight + 'px',opacity: !abTestLoding? 1 : 0 }" v-if="(infoList.type === 0 || infoList.type === 1)">
        <div class="rotate-container">
          <div class="block-1"></div>
          <div class="block-2"></div>
          <div class="block-3"></div>
        </div>
        <div class="first-page-container">
          <div class="first-page-left">
            <span class="title">简单好用的设计工具</span>
            <span class="des">设计不求人，AI助你零基础完成专业设计</span>
            <div
              class="button"
              @click="handleLocation('/designtools/designindex','首屏_开始设计')"
            >
              <span class="buttom-name">开始设计</span>
              <span class="buttom-icon"></span>
            </div>
          </div>
          <div class="first-page-right">
            <video src="./pagevideo.mp4" autoplay loop muted></video>
          </div>
        </div>
      </div>
      <NewFirstPageContent v-if="infoList.type === 2" :getScreenHeight="getScreenHeight" :getScreenWidth="getScreenWidth" @openLocation="handleLocation" :abTestLoding="abTestLoding"/>
      <!-- 导航 -->
      <div class="slider-container">
        <div
          class="top"
          :style="{ top: getHeaderActivityShow ? '30px' : '10px' }"
          v-show="scrollTop > 1200"
        >
          <div
            class="slider-item"
            v-for="(item, index) in sliderData"
            :class="{ active: activeSliderIndex === index }"
            @click="changeSliderPage(index)"
          >
            {{ item }}
          </div>
        </div>
        <!-- 为用户量身打造专属版本 -->
        <div class="vip-info-page">
          <div class="sub-title">为用户量身打造专属版本</div>
          <div class="small-des">满足不同用户需求，会员畅享专属授权保障</div>
          <div class="vip-item-box">
            <div class="vip-item" :style="{background:item.boxBackground}" v-for="item in vipData" :key="item.id" @click="handleLocation(item.link,item.moduleName)">
              <div class="vip-item-title">
                <span>{{item.title}}</span>
                <img v-lazy="require(`${item.imgUrl}`)"/>
              </div>
              <div class="vip-item-des">{{item.des}}</div>
              <div class="vip-item-button" :style="{background:item.buttonBackground,color:item.buttonTextColor,border:item.buttonBorderColor?`1px solid ${item.buttonBorderColor}`:null}">{{item.buttonText}}</div>
            </div>
          </div>
        </div>
        <!-- 一分钟生成设计 -->
        <div class="one-min-page" ref="oneMinPage">
          <div class="sub-title">一分钟生成设计</div>
          <div class="small-des">海量高品质可商用资源，助力快速生成设计</div>
          <div class="one-min-page-center">
            <div v-for="rowData in oneMinData" class="row">
              <div
                class="img-box"
                v-for="item in rowData"
                @click="handleLocation(item.location,item.moduleName)"
              >
                <img v-lazy="require(`${item.imgUrl}`)">
                <div class="pic-des">{{ item.des }}</div>
                <div class="sub-pic-des">{{ item.subDes }}</div>
                <div class="try-now-button">立即体验</div>
              </div>
            </div>
          </div>
        </div>
        <!-- AI智能设计 -->
        <div class="ai-page" ref="aiPage">
          <div class="sub-title">AI智能设计新时代</div>
          <div class="small-des">AI设计工具助力内容生产效率革命式提升</div>
          <div class="ai-page-container">
            <div v-for="rowData in aiData" class="row">
              <div
                class="img-box"
                v-for="item in rowData"
                @click="handleLocation(item.location,item.moduleName)"
              >
                <img v-lazy="require(`${item.imgUrl}`)">
                <div class="pic-des">{{ item.des }}</div>
                <div class="sub-pic-des">{{ item.subDes }}</div>
                <div class="point" v-if="item.des === '图片修复'"></div>
                <div class="video-container" v-if="item.des === '图片修复'">
                  <video src="./img/mate.mp4" autoplay loop muted></video>
                </div>
                <div class="try-now-button">立即体验</div>
              </div>
            </div>
          </div>
          <!-- <SmallButton
              @onButtonClick="
                handleLocation('https://aiart.chuangkit.com/matrix')
              "
            /> -->
        </div>

        <!-- 团队协作 -->
        <div class="team-work-page" ref="teamPage">
          <div class="sub-title">设计团队协作</div>
          <div class="small-des">为团队管理赋能，提高设计生产效率</div>
          <div class="team-work-page-box">
            <div v-for="rowData in teamData" class="row">
              <div
                class="img-box"
                v-for="item in rowData"
                @click="handleLocation(item.location,item.moduleName)"
              >
                <img v-lazy="require(`${item.imgUrl}`)">
                <div class="pic-des">{{ item.des }}</div>
                <div class="sub-pic-des">{{ item.subDes }}</div>
                <div class="try-now-button">立即体验</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 企业部分 -->
        <EnterprisePart @openLocation="handleLocation" ref="enterprise"/>
      </div>
      <!-- 海量高品质 -->
      <ResourceShowPage @openLocation="handleLocation" :getScreenWidth="getScreenWidth"/>
      <!-- 加入我们 -->
      <JoinUsPage/>
      <!-- 企业logo -->
      <div class="company-logo-box">
        <div class="sub-title">与170000家企业用户共同见证改变</div>
        <div class="small-des">创客贴感谢每一位企业合作伙伴的支持与信任</div>
        <div class="company-logo">
          <div class="company-bg left"></div>
          <div class="company-bg right"></div>
          <div class="row first-row">
            <div
              class="logo-box"
              v-for="item in firstCompanyRow"
              :style="{ backgroundImage: `url(${require(`${item}`)})` }"
            ></div>
          </div>
          <div class="row second-row">
            <div
              class="logo-box"
              v-for="item in secondCompanyRow"
              :style="{ backgroundImage: `url(${require(`${item}`)})` }"
            ></div>
          </div>
        </div>
      </div>
      <!-- 数据 -->
      <div class="data-box">
        <div class="rotate-container-res">
          <div class="block-1"></div>
          <div class="block-3"></div>
        </div>
        <div class="content-box">
          <div class="left">
            <div class="sub-title">创客贴，让设计触手可得</div>
            <div class="small-des">海量可商用设计资源，助你快速生成设计</div>
            <div
              class="button"
              @click="handleLocation('/designtools/designindex','产品数据_开始设计')"
            >
              <span class="buttom-name">开始设计</span>
              <span class="buttom-icon"></span>
            </div>
          </div>
          <div class="right">
            <div v-for="item in dataInfo" class="num-container">
              <div>
                <span class="number">{{ item.number }}</span>
                <span class="unit">{{ item.unit }}</span>
              </div>
              <div class="number-title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <HomePageFooter/>
    </div>
  </div>
</template>

<style lang="less">
  @import './homepage.less';
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import JoinUsPage from './comp/JoinUsPage'
  import EnterprisePart from './comp/EnterprisePart'
  import ResourceShowPage from './comp/ResourceShowPage'
  import HomePageFooter from './comp/HomePageFooter'
  import NewFirstPageContent from './comp/NewFirstPageContent'
  import tracker from "@/utils/tracker";
  import { getABtest } from '@/api';

  export default {
    inject: ['app'],
    data() {
      return {
        sliderData: ['快速出图', 'AI 设计', '团队协作', '企业服务'],
        activeSliderIndex: 0,
        vipData:[
          {
            title:'免费版',
            id:'free',
            des:'适合于个人体验学习使用，在线编辑设计',
            buttonText:'免费使用',
            buttonBackground:'',
            boxBackground:'linear-gradient(180deg, #FFF 47.7%, #EEF2F7 100%)',
            buttonTextColor:'#0773FC',
            buttonBorderColor:'#0773FC',
            link:'/designtools/designindex',
            imgUrl:'./img/free.svg',
            moduleName: '产品版本_免费版',
          },
          {
            title:'通用版',
            id:'standard',
            des:'适合个人或企业1人使用，畅享无水印高清下载',
            buttonText:'限时优惠',
            buttonBackground:'linear-gradient(90deg, #FF6464 2.95%, #FA2323 99.68%)',
            boxBackground:'linear-gradient(180deg, #FFF 47.7%, #EAF3FF 100%)',
            buttonTextColor:'#fff',
            buttonBorderColor:'',
            link:'/price/vip?fmodule=product_bzbvip&fpage=homepage',
            moduleName: '产品版本_通用版',
            imgUrl:'./img/standard.svg',
          },
          {
            title:'协作版',
            id:'premium',
            des:'适合10人以内团队使用,资源共享/在线协作/品牌管制',
            buttonText:'开启协作',
            buttonBackground:'linear-gradient(90deg, #FCEEE1 0%, #FFC9A0 100%)',
            boxBackground:'linear-gradient(180deg, #FFF 47.7%, #FFF4E9 100%)',
            buttonTextColor:'#6B3D1E',
            buttonBorderColor:'',
            link:'/price/vip?fmodule=product_gjbvip&fpage=homepage',
            moduleName: '产品版本_协作版',
            imgUrl:'./img/premium.svg',
          },
          {
            title:'旗舰版',
            id:'flagship',
            des:'适合于大型企业/跨地域/多子公司使用',
            buttonText:'预约演示',
            buttonBackground:'linear-gradient(270deg, #2B2C31 0%, #4E4F53 100%)',
            boxBackground:'linear-gradient(180deg, #FFF 47.7%, #E8EAF3 100%)',
            buttonTextColor:'#FFD092',
            buttonBorderColor:'',
            link:'/contech?semPosition=旗舰版咨询-品牌首页',
            imgUrl:'./img/flagship.svg',
          },
        ],
        oneMinData: [
          [
            {
              imgUrl: './img/fast-1.png',
              location: '/templatecenter',
              des: '海量设计资源',
              subDes: '50万原创模板、1亿版权素材、15万插画元素',
              moduleName:'快速出图_海量设计资源'
            },
            {
              imgUrl: './img/fast-2.png',
              location: '/designtools/designindex',
              des: '团队协作',
              subDes: '多人跨部门、跨地域设计协作',
              moduleName:'快速出图_团队协作'
            },
          ],
          [
            {
              imgUrl: './img/fast-3.png',
              location: '/templatecenter',
              des: '丰富的资源格式',
              subDes: '多种资源格式满足不同场景内容需求',
              moduleName:'快速出图_丰富的资源格式'
            },
            {
              imgUrl: './img/fast-4.png',
              location: '/designtools/designindex',
              des: '设计组件',
              subDes: '数据 & 功能组件让设计更生动',
              moduleName:'快速出图_设计组件'
            },
            {
              imgUrl: './img/fast-5.png',
              location: '/designtools/designindex',
              des: '快速分享',
              subDes: '一键快速分享主流社媒平台',
              moduleName:'快速出图_快速分享'
            },
          ],
        ],
        aiData: [
          [
            {
              imgUrl: './img/AIpic-01.svg',
              location: '/eliminate',
              des: '图片修复',
              subDes: '去物体、修痕迹',
              moduleName:'AI设计_图片修复'
            },
            {
              imgUrl: './img/AIpic-2.png',
              location: 'https://aiart.chuangkit.com/print/inpainting',
              des: '智能改图',
              subDes: '智能替换画面内容',
              moduleName:'AI设计_智能改图'
            },
          ],
          [
            {
              imgUrl: './img/AIpic-3.png',
              location: '/koutu',
              des: '智能抠图',
              subDes: '一键去除主体背景',
              moduleName:'AI设计_智能抠图'
            },
            {
              imgUrl: './img/AIpic-4.png',
              location: 'https://aiart.chuangkit.com/print/productimage',
              des: 'AI商品图',
              subDes: '智能迁移商品背景',
              moduleName:'AI设计_AI商品图'
            },
            {
              imgUrl: './img/AIpic-5.png',
              location: 'https://aiart.chuangkit.com/print/writingassistant',
              des: 'AI 文案',
              subDes: '智能文案写作助手',
              moduleName:'AI设计_AI文案'
            },
          ],
        ],
        teamData: [
          [
            {
              imgUrl: './img/team-1.png',
              location: '/designtools/designindex',
              des: '团队资源共享',
              subDes: '全员共享模板素材资源库',
              moduleName:'团队协作_团队资源共享'
            },
            {
              imgUrl: './img/team-2.svg',
              location: '/designtools/designindex',
              des: '品牌管制',
              subDes: '管控设计内容，避免品牌风险',
              moduleName:'团队协作_品牌管制'
            },
            {
              imgUrl: './img/team-3.png',
              location: '/designtools/designindex',
              des: '成员管理',
              subDes: '支持差异化成员管理',
              moduleName:'团队协作_成员管理'
            },
          ],
          [
            {
              imgUrl: './img/team-4.png',
              location: '/designtools/designindex',
              des: '设计审批',
              subDes: '不同场景可定制不同的审批流',
              moduleName:'团队协作_设计审批'
            },
            {
              imgUrl: './img/team-5.svg',
              location: '/designtools/designindex',
              des: '数据统计',
              subDes: '团队成员设计数据一目了然',
              moduleName:'团队协作_数据统计'
            },
            {
              imgUrl: './img/team-6.png',
              location: '/designtools/designindex',
              des: '离职交接',
              subDes: '内容资产不丢失，使用更安全',
              moduleName:'团队协作_离职交接'
            },
          ],
        ],
        location: '/designtools/designindex',
        firstCompanyRow: [
          './img/xiaomi.png',
          './img/mayi.png',
          './img/beike.png',
          './img/meituan.png',
          './img/huawei.png',
          './img/alibaba.png',
          './img/jd.png',
          './img/xiecheng.png',
          './img/walmart.png',
        ],
        secondCompanyRow: [
          './img/jianshe.png',
          './img/jiaotong.png',
          './img/pingan.png',
          './img/aiqiyi.png',
          './img/xiaored.png',
          './img/tesla.png',
          './img/guangfa.png',
          './img/didi.png',
        ],
        dataInfo: [
          { title: '累计注册用户', number: '7400', unit: '万+' },
          { title: '企业商业用户', number: '17', unit: '万+' },
          { title: '高品质原创模板', number: '50', unit: '万+' },
          { title: '版权素材', number: '1', unit: '亿+' },
          { title: '原创插画元素', number: '15', unit: '万+' },
        ],
        qrcodeType: 'mobile',
        showQrCodeBox: false,
        getScreenWidth: 0,
        resizeTimer: null,
        abTestLoding: true,
        infoList:{
          type: 0
        },
        burientInfo:{
          ABTest_id:'6',
          ABTest_type:'用户设备灰度',
          ABTest_group:''
        }
      };
    },
    metaInfo() {
      return {
        title: '创客贴 - 简单好用的设计工具',
        meta: [
          { 
            name: 'description',
            content: '创客贴是一款简单好用的在线设计工具,提供海量设计模板和素材,支持团队协作,让设计更简单。'
          },
          {
            name: 'keywords',
            content: '设计工具,在线设计,图片设计,AI设计,团队协作'
          }
        ]
      }
    },
    components: {
      EnterprisePart,
      ResourceShowPage,
      JoinUsPage,
      HomePageFooter,
      NewFirstPageContent
    },
    props: {
      userInfo: {
        default: {},
      },
      isLogin: {
        default: false,
      },
      scrollTop: {
        default: 0,
      },
      isResize: {
        default: false,
      },
    },
    watch: {
      isLogin: {
        handler(v) {
          if (v) {
            window.location.href = this.location;
          }
        },
        immediate: true,
      },
      scrollTop: {
        handler(v) {
          if (v > this.getScreenHeight && v < this.getScreenHeight + 976) {
            this.activeSliderIndex = 0;
          } else if (
            v > this.getScreenHeight + 976 &&
            v < this.getScreenHeight + 976 + 1059
          ) {
            this.activeSliderIndex = 1;
          } else if (
            v > this.getScreenHeight + 976 + 1059 &&
            v < this.getScreenHeight + 976 + 1059 * 2
          ) {
            this.activeSliderIndex = 2;
          } else if (v > this.getScreenHeight + 976 + 1059 * 2) {
            this.activeSliderIndex = 3;
          }
        },
      },
      isResize(v) {
        // if (v) {
        //   this.getScreenWidth = window.innerWidth
        // }
      },
    },
    mounted() {
      this.getABTest(),
      tracker.urlChange(),
      this.getScreenWidth = window.innerWidth,
      window.addEventListener('resize', this.throttledResize),
      this.timer = setInterval(() => {
        this.currentCustomNav++;
        if (this.currentCustomNav === 4) {
          this.currentCustomNav = 0;
        }
      }, 5000)
    },
    destroyed() {
      sessionStorage.removeItem('newIndexPageTap');
      clearInterval(this.timer);
      // window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
      ...mapGetters({
        teamId: 'getTeamId',
        getHeaderActivityShow: 'getHeaderActivityShow',
      }),

      getScreenHeight() {
        return window.innerHeight;
      },
    },

    methods: {
      ...mapActions(['setLogregShow']),

      handleLocation(location,moduleName) {
        //每次埋都要加sessionStorage 历史逻辑
        try {
          let forwardData = {
            forward_module_name: moduleName||'',
            forward_page_name: '品牌首页',
          }
          sessionStorage.setItem('forward', JSON.stringify(forwardData))
        } catch (error) {
          console.log('error',error)
        }
        this.location = location;
        this.setLogregShow(1);
      },

      throttledResize() {
        if (this.resizeTimer) {
          clearTimeout(this.resizeTimer);
        }
        this.resizeTimer = setTimeout(() => {
          console.log('window.innerWidth', window.innerWidth);
          this.getScreenWidth = window.innerWidth;
        }, 500);
      },
      async getABTest(){
        const ckt_deviceId = await tracker.getCktDeviceId();
        const params={
          platform:1,
          scene:6,
          deviceId:ckt_deviceId
        }
        getABtest(params).then(res => {
         const code =res.body.code
         const data =res.body.data
         if (code === 200){
          this.abTestLoding =false
          this.infoList = data.infoList[0] || {type: 0}
          this.burientInfo.ABTest_group = this.infoList.key
          sessionStorage.setItem('burientInfoAb', JSON.stringify(this.burientInfo))
          try {
              let forwardData = {
                forward_module_name: '',
                forward_page_name: '品牌首页',
              }
              sessionStorage.setItem('forward', JSON.stringify(forwardData))
              window.gio('track', 'homepage_view',this.burientInfo);
            } catch (error) {
              console.error('gio error \n' + error);
            }
         } else {
          this.abTestLoding =false
         }
        }).catch(error => {
          if (error.code === 'ECONNABORTED') {
            this.abTestLoding =false
          } else {
            this.abTestLoding =false
          }
        });
      },
      changeSliderPage(index) {
        console.log('index', index);
        // this.activeSliderIndex = index;
        let scrollDis = 0;
        switch (index) {
          case 0:
            scrollDis = this.getScreenHeight + 702;
            break;
          case 1:
            scrollDis = this.getScreenHeight + 702 + 976 + 60;
            break;
          case 2:
            scrollDis = this.getScreenHeight + 702 +976 + 1059 + 60;
            break;
          case 3:
            scrollDis = this.getScreenHeight + 702 + 976 + 1059 + 1059 + 60;
            break;
          default:
            break;
        }
        this.$emit('handleChangeNavScroll', scrollDis);
      },
      showQrCode(type) {
        this.qrcodeType = type;
        this.showQrCodeBox = true;
      },
    },
  };
</script>
