// Require the polyfill before requiring any other modules.
require('intersection-observer');
import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import store from './store';
import initInterceptors from './config/httpInterceptors';
import polyfill from './utils/polyfill';
import { Checkbox, Icon, Popover, Tooltip, Modal, Button, DatePicker,Table,Empty,Divider } from 'ant-design-vue';
import Lantern from 'lantern-ui';
import 'lantern-ui/lib/lantern.css';
import * as vClickOutside from 'v-click-outside-x';
import VueLazyload from 'vue-lazyload';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { init } from 'ckt_oss';

const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '/font-v2/font_3539972_nbijih4jvg.js',
});
const MyNewIcon = Icon.createFromIconfontCN({
  scriptUrl: '/font-v2/font_2521703_zub0x7knibe.js',
});
init();

initInterceptors();
polyfill();
Vue.use(Checkbox);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Icon);
Vue.use(Table);
Vue.use(Empty);
Vue.use(Divider);
Vue.use(DatePicker);
Vue.use(VueRouter);
Vue.use(Lantern);
Vue.use(vClickOutside);
Vue.use(VueLazyload, {
  observer: true,
  lazyComponent: true,
});
Vue.component('my-icon', MyIcon);
Vue.component('my-new-icon', MyNewIcon);
Vue.use(Modal)
Vue.use(Button)

// Vue.config.devtools = true;

import router from './config/routes';

Sentry.init({
  Vue,
  environment: workEnv,
  dsn: 'https://6cdecf9f39b84fb8bfb23266af4600c4@t-trace.chuangkit.com/7',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [/^\/[^/]/],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
// 创建一个路由器实例
// 并且配置路由规则
// const router = new VueRouter({
//   mode: 'history',
//   base: __dirname,
//   routes: routes,
//   scrollBehavior
// })

// 现在我们可以启动应用了！
// 路由器会创建一个 App 实例，并且挂载到选择符 #app 匹配的元素上。
const app = new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}); //.$mount('#app')

// 第三方登录
window.thirdpartyCB = () => {
  app.$children[0].handleLoginSucceed();
};

if (!/localhost/.test(window.location.href)) {
  document.domain = 'chuangkit.com';
}
