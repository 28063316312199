<template>
    <div
      class="seo-headWrap"
    >
        <header>
            <div class="logo-wrap">
            <!-- SEO 需求 -->
            <div class="collectionWrap">
                <a class="logo-img" href='/designtools/designindex'>
                <!-- SEO 需求 -->
                <span class="undis">创客贴</span>
                </a>
                <template>
                <span class="collect-dot"></span>
                <div class="collectionImg">拖动 LOGO 到书签栏，立即收藏创客贴</div>
                </template>
            </div>
            </div>
            <ul class="nav-list">
                <li
                    v-for="value in navList"
                    :key="value.href"
                    :id="value.key"
                    class="nav-list-item"
                >
                    <a v-if="value.href" :href="value.href">{{ value.des }}</a>
                    <span v-if="!value.href && value.des !== '更多'" >{{ value.des }}</span>
                    <ul v-if="value.key === 'template_seo'" class="template-dropdown">
                        <div class="template-categories">
                            <div v-for="category in templateList" :key="category.des" class="category-section">
                                <h3 class="category-title">{{category.des}}</h3>
                                <ul class="category-items">
                                    <li v-for="item in category.list" 
                                        :key="item.des" 
                                        class="category-item">
                                        <a :href="item.href" target="_blank">{{item.des}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ul>
                    <ul v-if="value.key === 'aitools_seo'" class="template-dropdown aitools-dropdown">
                        <div class="template-categories">
                            <div v-for="category in aiToolsList" :key="category.des" class="category-section">
                                <h3 class="category-title">{{category.des}}</h3>
                                <ul class="category-items">
                                    <li v-for="item in category.list" 
                                        :key="item.des" 
                                        class="category-item">
                                        <a :href="item.href" target="_blank">{{item.des}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ul>
                    <ul v-if="value.key === 'designservice_seo'" class="template-dropdown designservice-dropdown">
                        <div class="template-categories">
                            <div v-for="category in designServiceList" :key="category.des" class="category-section">
                                <h3 class="category-title">{{category.des}}</h3>
                                <ul class="category-items">
                                    <li v-for="item in category.list" 
                                        :key="item.des" 
                                        class="category-item">
                                        <a :href="item.href" target="_blank">{{item.des}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ul>
                    <ul v-if="value.des === '更多'" class="show-more-list">
                        <li v-for="item in moreList" :key="item.des">
                            <a :href="item.href" target="_blank">{{ item.des }}</a>
                        </li>
                    </ul>
                    <!-- 客户端二维码 -->
                    <div v-if="value.des == '下载APP'" class="client-dropdown">
                        <div class="app-download-code"></div>
                        <div class="des des-one">iOS / Android</div>
                        <div class="des des-two">下载APP畅享多端协作</div>
                    </div>
                </li>
            </ul>
            <template>
                <div class="open-vip_warp"  @mouseenter="showVipModal(1)" @mouseleave="showVipModal(2)">
                    <div class="open-vip-button">
                        <span class="vip-icon-test">开通会员</span>
                    </div>
                </div>
            </template>
            <!-- 企业服务入口 -->
            <div class="enterprise-service-btn" >
                <span class="enterprise-service-title">企业服务</span>
                <ul class="enterprise-service-list">
                    <li class="modal_list_item" v-for="item in enterServiceList" :key="item.id">
                        <a class="modal_right" :href="item.href">
                            <span>{{ item.title }}
                                <i class="icon_name" v-if="item.icon_name">{{ item.icon_name }}</i>
                            </span>
                            <span>{{ item.desc }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div  class="login">
                <span class="avatar-text">
                    <a rel="nofollow" @click="logReg(1, '页顶_右侧登录注册文字')" >登录注册</a>
                </span>
            </div>
        </header>
    </div>
  </template>
  
  <script>
    import seoHeader from './seoHeader'
    const PERSONAL_HOVER_LIST = [
      {
        title: "20万+精品设计模板",
        subtitle: "海量海报、封面图、视频、GIF模板免费使用",
        img: {
          top: 0,
          left: 0,
        },
      },
      {
        title: "8000万+版权素材",
        subtitle: "高清图片、字体、音乐一站式使用",
        img: {
          top: 0,
          left: -24,
        },
      },
      {
        title: "个人商用授权",
        subtitle: "个人自媒体、个人网店等个人为授权主体的使用",
        img: {
          top: 0,
          left: -48,
        },
      },
      {
        title: "会员高级功能特权",
        subtitle: "畅享智能尺寸变换、超大存储空间、文件夹管理等",
        img: {
          top: 0,
          left: -72,
        },
      },
    ];
    const BUSINESS_HOVER_LIST = [
      {
        title: "企业商用版权",
        subtitle: "20万+正版模、8000万+高清素材企业正版商用",
        img: {
          top: 0,
          left: -48,
        },
      },
      {
        title: "品牌管理",
        subtitle: "创建团队模板、素材，轻松让团队成员的营销素材符合品牌规范",
        img: {
          top: 0,
          left: -96,
        },
      },
      {
        title: " 团队协作",
        subtitle: "支持设计协作，素材共享，赋予团队每个成员设计能力",
        img: {
          top: 0,
          left: -120,
        },
      },
      {
        title: "企业发票",
        subtitle: "正规企业发票，线上申请，报销无忧",
        img: {
          top: 0,
          left: -144,
        },
      },
    ];
    export default {
      name: "SeoHeader",
      data() {
        return {
          PERSONAL_HOVER_LIST, // 个人vip悬浮列表
          BUSINESS_HOVER_LIST, // 企业vip悬浮列表
          enterServiceList:[
              {
                  title:'企业旗舰版',
                  desc:'面向中大型企业使用，功能可定制，商业授权范围广',
                  href:'/contech',
                  id:2
              },
              {
                  title:'开放平台',
                  icon_name:'API',
                  desc:'企业全端接入，易于集成，即刻拥有智能设计能力',
                  href:'/terms/introduce',
                  id:1
              },
              {
                  title:'渠道合作',
                  desc:'创客贴渠道代理/分销商招募，携手共赢亿级红利',
                  href:'/activity/channelAgentPage',
                  id:3
              },
              {
                  title:'定制设计',
                  icon_name:'企业',
                  desc:'专业设计师团队，专为企业提供规模化、高品质的创意设计服务',
                  href:'/design-service?from=enterprise_service',
                  id:4
              },
              {
                  title:'印刷制作',
                  icon_name:'企业',
                  desc:'印刷喷绘、产品包装、礼盒定制等一站式采购，支持在线下单',
                  href:'/print/printcenter',
                  id:5
              }

          ],
          navList: seoHeader.NAV_LIST,
          moreList: seoHeader.MORE_LIST,
          templateList: seoHeader.TEMPLATE_LIST,
          aiToolsList: seoHeader.AI_TOOLS_LIST,
          designServiceList: seoHeader.DESIGN_SERVICE_LIST
        };
      },
      mounted() {
        this.curScreenWidth = window.innerWidth;
      },
      methods: {
      },
    };
  </script>
  
  <style lang="less" scoped>
  @import "./seoHeader.less";
  </style>
  